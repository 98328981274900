import React from "react"
import { graphql } from "gatsby"
import {
  Charities,
  Studiomade,
  Signature,
  LightsLeft,
  LightsRight,
} from "../images"
import LottieContainer from "../components/lottie-container"

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isStopped: false, isPaused: false }
  }

  render() {
    const post = this.props.data.wordpressWpXmas

    return (
      <section className="page-content">
        <a className="studiomade-logo" href="https://studiomade.co">
          <img src={Studiomade} alt="studiomade logo" />
        </a>
        <LottieContainer />
        <div className="row centered">
          <img src={LightsLeft} alt="lights" className="lights-left" />
          <img src={LightsRight} alt="lights" className="lights-right" />
          <div className="col col-5 col-text">
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
            <p>
              This year has definitely been a challenging one. With this in
              mind, we wanted to show our support to those that need it. For our
              Christmas giving this year we have donated to the Trussell Trust
              on your behalf. They are an incredible charity that supports a
              nationwide network of food banks and provides emergency food and
              support to people in poverty.
            </p>

            <img src={Charities} alt="Shelter + International Needs" />

            <p>
              {" "}
              From all the team at Studiomade we hope you have a really lovely
              Christmas and we will see you in the New Year!!
            </p>
            <img src={Signature} alt="Phil signature" class="signature" />
            <p className="phil"> Phil Reid </p>
            <p className="phil-title"> Founder & Strategic Director </p>
          </div>
        </div>
      </section>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    wordpressWpXmas(slug: { eq: $slug }) {
      title
      slug
      content
    }
  }
`
